const { createSlice } = require("@reduxjs/toolkit");
const {
  EditRoleMenuMappingMiddleware,
} = require("services/Middleware/EditRoleMenuMappingMiddleware");
const initialState = {
  status: "idle",
  message: "",
};
const EditRoleMenuMappingSlice = createSlice({
  name: "edit/role/menu/mapping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EditRoleMenuMappingMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditRoleMenuMappingMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action.payload.message;
      })
      .addCase(EditRoleMenuMappingMiddleware.rejected, (state, action) => {
        state.status = action.payload;
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export default EditRoleMenuMappingSlice.reducer;
export const editRoleMenuMappingSelector = (state) =>
  state.EditRoleMenuMappingSlice;
