import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetRoleMenuMappingMiddleware } from "services/Middleware/GetRoleMenuMappingMiddleware";

const useRoleMenuMapping = () => {
  const [credential] = useState({
    userId: localStorage.getItem("userID"),
    role_id: localStorage.getItem("r_id"),
    menu_id: "",
  });
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const getRoleMenuMapping = async () => {
    try {
      await dispatch(GetRoleMenuMappingMiddleware(credential)).unwrap();
    } catch (error) {
      setIsError(true);
      setErrorMessage(error);
    }
  };
  useEffect(() => {
    getRoleMenuMapping();
  }, []);
  return { errorMessage, isError };
};

export default useRoleMenuMapping;
