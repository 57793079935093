export const getDate = (dates) => {
  const date = new Date(dates);
  const now = new Date();
  const diff = Math.abs(now - date);
  // 1 second = 1000ms
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  let agoString;
  if (weeks > 0) {
    agoString = weeks === 1 ? "1 week ago" : `${weeks} week ago`;
  } else if (days > 0) {
    agoString = days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    agoString = hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    agoString = minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    agoString = "just now";
  }
  return agoString;
};
