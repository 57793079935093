const { createAsyncThunk } = require("@reduxjs/toolkit");
const { default: FetchData } = require("services/ApiCall/FetchData");

export const GetDistrictsMiddleware = createAsyncThunk(
  "get/districts",
  async (credential,{rejectWithValue}) => {
    console.log(credential,'asynthun')
    try {
      const response = await FetchData("POST", credential, "districts");
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
        return rejectWithValue(error?.message)
    }
  }
);
