const { createSlice } = require("@reduxjs/toolkit");
const {
  GetCommunicationListMiddleware,
} = require("services/Middleware/GetCommunicationListMiddleware");

const initialState = {
  status: "idle",
  totalCount: null,
  message: "",
};
const GetCommunicationListSlices = createSlice({
  name: "get/communication/list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCommunicationListMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetCommunicationListMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.totalCount = action.payload.totalCount;
      })
      .addCase(GetCommunicationListMiddleware.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const GetCommunicationListSelector = (state) =>
  state.GetCommunicationListSlices;
export default GetCommunicationListSlices.reducer;
