import { NotificationDetailMiddleware } from "services/Middleware/NotificationDetailMiddleware";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const NotificationDetailsSlice = createSlice({
  name: "notification/details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(NotificationDetailMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(NotificationDetailMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action?.payload?.message;
        state.data = action.payload.data;
      })
      .addCase(NotificationDetailMiddleware.rejected, (state, action) => {
        console.log(action);
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default NotificationDetailsSlice.reducer;
export const { getNotificationByStatus } = NotificationDetailsSlice.actions;
export const GetNotificationDetailsSelector = (state) =>
  state.NotificationDetailsSlice;
