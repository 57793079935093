import FetchData from "services/ApiCall/FetchData";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const UpdateNotifcationReviewStatusMiddleware = createAsyncThunk(
  "notification/review",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData(
        "POST",
        credential,
        "update_social_campaign_review_status"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
