const initialState = {
  status: "idle",
  message: "",
};
const EditUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_USER_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "EDIT_USER_SUCCESS":
      return {
        ...state,
        status: "success",
        message: action.payload.message,
      };
    case "EDIT_USER_ERROR":
      return {
        ...state,
        status: "failed",
        message: action.payload.message,
      };
    default:
      return state;
  }
};
export const EditUserSelector = (state) => state.EditUserReducer;
export default EditUserReducer;
