const FetchData = async (method, data, endpoint) => {
  if (method === "GET") {
    const promise = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${endpoint}`
    );

    return promise.json();
  } else {
    const promise = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const response = await promise.json();
    return response;
  }
};
export default FetchData;
