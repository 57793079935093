const { createSlice } = require("@reduxjs/toolkit");
const {
  GetNotificationMiddleware,
} = require("services/Middleware/GetNotificationMiddleware");

const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const GetNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetNotificationMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetNotificationMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action?.payload?.message;
        state.data = action.payload.data.reverse();
      })
      .addCase(GetNotificationMiddleware.rejected, (state, action) => {
        console.log(action);
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default GetNotificationSlice.reducer;

export const GetNotificationSelector = (state) => state.GetNotificationSlice;
