import React, { useEffect } from "react";
import { useState } from "react";
import { Badge, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { NotificationDetailMiddleware } from "services/Middleware/NotificationDetailMiddleware";
import { GetNotificationDetailsSelector } from "services/Slices/NotificationDetailsSlice";
import SpinnerComponent from "Utils/SpinnerComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const NotificationDetails = (props) => {
  const [notificationList, setNotificationList] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const data = {
    labels: ["Total", "Failed", "Sent", "Schedule"],
    datasets: [
      {
        label: "Notification",
        data: [
          notificationList?.length,
          notificationList?.filter(
            (item) => item.notificationStat === "Notification Error" && item
          )?.length,

          notificationList?.filter(
            (item) => item.notificationStat === "Delivered" && item
          )?.length,

          notificationList?.filter(
            (item) => item.notificationStat === null && item
          )?.length,
        ],
        backgroundColor: [
          "rgba(0,128,0,1)",
          "rgba(255,0,0,1)",
          "rgba(56, 202, 179, 1)",
          "rgba(255, 255, 0, 1)",
        ],
        borderColor: [
          "rgba(0,128,0,1)",
          "rgba(255,0,0,1)",
          "rgba(56, 202, 179, 1)",
          "rgba(255, 255, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const config = {
    barThickness: 50,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  const [filterData, setFilteredData] = useState([]);
  const [notificationDuration, setNotificationDuration] =
    useState("All Notification");
  const dispatch = useDispatch();
  const notificationSelector = useSelector(GetNotificationDetailsSelector);
  const getNotificationDetails = async () => {
    const socialId = localStorage.getItem("s_id");
    try {
      const res = await dispatch(
        NotificationDetailMiddleware({ socialId })
      ).unwrap();
      // console.log(notificationSelector, "notificationSelector");
      setNotificationList(res?.data);
      setFilteredData(res?.data);
      setLoading(false);
    } catch (error) {
      setShowMessage(true);
      toast.error(error);
    }
  };
  const filteredData = (value) => {
    if (value === "All Notification") {
      setFilteredData(notificationList);
    } else {
      const data = notificationList?.filter(
        (item) => item.notificationStat === value
      );
      setFilteredData(data);
    }
  };
  useEffect(() => {
    setLoading(true);
    getNotificationDetails();
  }, []);
  return (
    <div>
      <Bar data={data} options={config} />
      <Card className="box-shadow-0">
        <div className="breadcrumb-header justify-content-between">
          <ButtonGroup className="ms-2 mt-2 mb-2">
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                aria-expanded="false"
                aria-haspopup="true"
                className="btn ripple btn-rounded btn-primary"
                data-bs-toggle="dropdown"
                id="dropdownMenuButton4"
                type="button"
              >
                {notificationDuration}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{ margin: "0px" }}
                className="dropdown-menu tx-13"
              >
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setNotificationDuration("All Notification");
                    filteredData("All Notification");
                  }}
                >
                  All Notification
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setNotificationDuration("Sent");
                    filteredData("Delivered");
                  }}
                >
                  Sent
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setNotificationDuration("Failed");
                    filteredData("Notification Error");
                  }}
                >
                  Failed
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setNotificationDuration("Pending");
                    filteredData(null);
                  }}
                >
                  Pending
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ButtonGroup>
        </div>
        <div className="container">
          <Card.Body className="pt-0 position-relative">
            {loading ? (
              <SpinnerComponent />
            ) : (
              <ul className="notification">
                {filterData?.length === 0
                  ? "No Notification"
                  : filterData?.map((item, index) => (
                      <li key={index}>
                        <div className="notification-time">
                          <span className="date">today</span>
                          <span className="time">02:52</span>
                          <span className="time">
                            {item?.notificationSentTime?.split(" ")[1]}
                          </span>
                        </div>
                        <div className="notification-body">
                          <div className="media mt-0 ">
                            <div className="mb-3  me-3 my-auto shadow">
                              <Badge
                                bg=""
                                className="float-end badge notification-badge"
                              >
                                <span
                                  className={`tx-11 font-weight-semibold  ${
                                    item.notificationStat ===
                                    "Notification Error"
                                      ? " text-danger"
                                      : "text-success"
                                  }`}
                                >
                                  {item?.notificationStat}
                                </span>
                              </Badge>
                            </div>
                            <div className="media-body">
                              <div className="d-flex align-items-center">
                                <div className="mt-0">
                                  {/* <h5 className="mb-1 tx-15 font-weight-semibold text-dark"></h5> */}
                                  <p className="mb-0 tx-13 mb-0 text-muted">
                                    {item?.notificationLog}
                                  </p>
                                </div>
                                <div className="ms-auto">
                                  <Badge
                                    bg=""
                                    className="float-end badge notification-badge"
                                  >
                                    <span
                                      className={`tx-11 font-weight-semibold ${
                                        item?.notificationStat ===
                                        "Notification Error"
                                          ? " text-danger"
                                          : "text-success"
                                      }`}
                                    >
                                      {item?.notificationStat === null
                                        ? "Pending"
                                        : item.notificationStat ===
                                          "Notification Error"
                                        ? "Failed"
                                        : "Delivered"}
                                    </span>
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
              </ul>
            )}
          </Card.Body>
        </div>
      </Card>
      {showMessage && <ToastContainer />}
      {/* <!-- row closed --> */}
    </div>
  );
};

export default NotificationDetails;
