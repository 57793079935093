import { createSlice } from "@reduxjs/toolkit";
import { GetRoleMenuMappingMiddleware } from "services/Middleware/GetRoleMenuMappingMiddleware";
const initialState = {
  status: "idle",
  data: [],
  message: "",
};
export const RoleMenuMappingSlice = createSlice({
  name: "get/role/menu/mapping",
  initialState,
  reducers: {
    updateRoleMenuMappingStatus: (state, action) => {
      const { data } = state;
      const { id, status } = action.payload;
      const filteredData = data.map((item) =>
        item.id === id
          ? { ...item, status: status === 1 ? "Active" : "Inactive" }
          : { ...item }
      );
      return {
        ...state,
        data: filteredData,
      };
    },
    deleteRoleMenuMapping: (state, action) => {
      const { data } = state;
      const filteredData = data.filter((item) => item.id !== action.payload);
      return {
        ...state,
        data: filteredData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetRoleMenuMappingMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetRoleMenuMappingMiddleware.fulfilled, (state, action) => {
        const { data } = action.payload;
        const customizedData = data
          .filter((item) => item.menu_name !== "" && item.status !== "2")
          .map((item) => ({
            ...item,
            status: item.status === "1" ? "Active" : "Inactive",
          }));
        state.status = "success";
        state.data = customizedData;
      })
      .addCase(GetRoleMenuMappingMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const getRoleMenuMappingSelector = (state) => {
  console.log(state.RoleMenuMappingSlice);
  return state.RoleMenuMappingSlice;
};
export const { updateRoleMenuMappingStatus, deleteRoleMenuMapping } =
  RoleMenuMappingSlice.actions;
export default RoleMenuMappingSlice.reducer;
