// import ActionCreatorError from "services/ActionCreator/ActionCreatorError";


import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchData from "services/ApiCall/FetchData";


export const GetUserTypesMiddleware = createAsyncThunk(
  "userType",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData(
        "POST",
        credential,
        "get_user_type_list"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
