const { createAsyncThunk } = require("@reduxjs/toolkit");

export const GetUserRoleMiddleware = createAsyncThunk(
  "get/role",
  async (credential, { rejectWithValue }) => {
    try {
      const promise = await fetch(
        `${process.env.REACT_APP_BASE_URL}/get_user_role_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credential),
        }
      );
      const response = await promise.json();
      console.log(response)
      const { response_code, message } = response;
      console.log(response);
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
