
import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchData from "services/ApiCall/FetchData";

export const AddUserTypeMiddlware = createAsyncThunk(
  "add/userType",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData("POST", credential, "add_user_type");
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
