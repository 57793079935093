const { createSlice } = require("@reduxjs/toolkit");
const { AddUserRoleMiddleware } = require("services/Middleware/AddUserRoleMiddleware");
const initialState = {
    status: "idle",
    message: "",
  };
const AddUserRoleSlice = createSlice({
    name:"user/role",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(AddUserRoleMiddleware.pending,(state)=>{

        }).addCase(AddUserRoleMiddleware.fulfilled,(state,action)=>{

        }).addCase(AddUserRoleMiddleware.rejected,(state,action)=>{

        }).addDefaultCase((state)=>({...state}))
    }
})
export const AddUserRoleSelector = (state) => state.AddUserRoleSlice;
export default AddUserRoleSlice.reducer;