import { Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="main-error-wrapper page page-h">
      <div className="square-box">
        {" "}
        <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>{" "}
        <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>{" "}
        <div></div> <div></div> <div></div>{" "}
      </div>
      <div
        className="page"
        // onClick={() => Switcherdatacustam.Swichermainrightremove()}
      >
        {/* <!-- Main-error-wrapper --> */}
        <div className="main-error-wrapper page page-h">
          <div>
            <h1 className="">
              404<span className="tx-20">error</span>
            </h1>
            <h2 className="">
              Oopps. The page you were looking for doesn't exist.
            </h2>
            <h6 className="">
              You may have mistyped the address or the page may have moved.
            </h6>
            <Button
              className="btn btn-primary"
              onClick={() => {
                navigate("/");
              }}
            >
              Back to Home
            </Button>
          </div>
        </div>
        {/* <!-- /Main-error-wrapper --> */}
      </div>
    </div>
  );
};

export default NotFound;
