import { GetStateMiddleware } from "services/Middleware/GetStateMiddleware";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  status: "",
  data: [],
  message: "",
};
const StateSlice = createSlice({
  name: "states",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetStateMiddleware.pending, (state) => {
      
        state.status = "loading";
      })
      .addCase(GetStateMiddleware.fulfilled, (state, action) => {
        console.log(action, "action in the fullfilled");
        const customizedField = action.payload.states.map((item) => ({
          label: item.state_name,
          value: item.state_id,
        }));
        state.status = "success";
        state.message = action?.payload?.message;
        state.data = customizedField;
      })
      .addCase(GetStateMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const stateSelector = (state) => state.StateSlice;
export default StateSlice.reducer;
