import ChangePasswordMiddleware from "services/Middleware/ChangePasswordMiddleware";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  status: "idle",
  message: "",
};
const ChangePasswordSlice = createSlice({
  name: "request/changePassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ChangePasswordMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ChangePasswordMiddleware.fulfilled, (state, action) => {
        state.message = action.payload;
        state.status = "success";
      })
      .addCase(ChangePasswordMiddleware.rejected, (state,action) => {
        state.status = "failed";
        state.message = action.payload
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const changePasswordSelector = (state) => {
  return state.ChangePasswordSlice;
};
export default ChangePasswordSlice.reducer;
