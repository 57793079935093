const { createSlice } = require("@reduxjs/toolkit");
const {
  UpdateNotifcationReviewStatusMiddleware,
} = require("services/Middleware/UpdateNotifcationReviewStatusMiddleware");
const initialState = {
  status: "idle",
  message: "",
};
const UpdateNotificationReviewSlice = createSlice({
  name: "review/notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateNotifcationReviewStatusMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        UpdateNotifcationReviewStatusMiddleware.fulfilled,
        (state, action) => {
          state.status = "success";
          state.message = action.payload.message;
        }
      )
      .addCase(
        UpdateNotifcationReviewStatusMiddleware.rejected,
        (state, action) => {
          state.status = "failed";
          state.status = action.payload;
        }
      )
      .addDefaultCase((state) => ({ ...state }));
  },
});

export default UpdateNotificationReviewSlice.reducer;
