import Sidebar from "components/Menu/Sidebar";
import NavBar from "components/NavBar/NavBar";
import React, { useState } from "react";
import { Navigate, Outlet } from "react-router";

const ProtectedRoute = () => {
  const [isUserLoggedIn] = useState(localStorage.getItem("userID"));
  if (!isUserLoggedIn) {
    return <Navigate to="/login" />;
  }
  const responsiveSidebarclose = () => {
    //leftsidemenu
    if (window.innerWidth < 992) {
      document.querySelector(".app")?.classList.remove("sidenav-toggled");
    }
    //rightsidebar
    document.querySelector(".sidebar-right")?.classList.remove("sidebar-open");
    //swichermainright
    document.querySelector(".demo_changer")?.classList.remove("active");
    let Rightside = document?.querySelector(".demo_changer");
    if (Rightside !== null) {
      Rightside.style.right = "-270px";
    }
  };
  function Sidebargone(gone) {
    if (gone.matches) {
      // If media query matches
      document.querySelector("body")?.classList.add("sidebar-gone");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-gone");
      document.querySelector("body")?.classList.remove("sidenav-toggled");
    }
  }
  var gone = window.matchMedia("(max-width: 1024px)");
  Sidebargone(gone); // Call listener function at run time
  gone.addListener(Sidebargone);
  return (
    <div className="horizontalMenucontainer">
      <div className="page">
        <div className="open">
          <NavBar />
          <div className="jumps-prevent" style={{ paddingTop: "63px" }}>
            <Sidebar />
          </div>
        </div>
        <div
          className="main-content app-content"
          onClick={() => {
            responsiveSidebarclose();
          }}
        >
          <div className="side-app">
            <div className="main-container container-fluid mt-5">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProtectedRoute;
