import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetNotificationDetailsMiddleware } from "services/Middleware/GetNotificationDetailsMiddleware";
import { GetNotificationDetailsSelector } from "services/Slices/NotificationDetailsSlice";
import { UpdateNotifcationReviewStatusMiddleware } from "services/Middleware/UpdateNotifcationReviewStatusMiddleware";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const GiveReviewToNotification = () => {
  const [reviewRemarks, setReviewRemarks] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const selector = useSelector(GetNotificationDetailsSelector);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const handleSubmit = async (reviewStatus) => {
    if (
      (reviewStatus === 2 && reviewRemarks.length > 0) ||
      reviewStatus === 1
    ) {
      try {
        const response = await dispatch(
          UpdateNotifcationReviewStatusMiddleware({
            socialId: id,
            reviewStatus,
            userId: localStorage.getItem("userID"),
            reviewRemarks,
          })
        ).unwrap();
        const { message } = response;
        setShowMessage(true);
        toast.success(message);
        setTimeout(() => {
          navigate("/notification");
        }, 3000);
      } catch (error) {
        setShowMessage(true);
        toast.success(error.message);
      }
    }
  };
  const getParticularNotificationDetails = async () => {
    try {
      await dispatch(
        GetNotificationDetailsMiddleware({ socialId: id })
      ).unwrap();
    } catch (error) {
      setShowMessage(true);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getParticularNotificationDetails();
  }, []);
  return (
    <>
      <Row className="row-sm">
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              <h4 className="card-title mb-1">Add User Role</h4>
            </Card.Header>
            <Card.Body className="pt-0 position-relative">
              <Form>
                <div className="">
                  <Form.Group className="form-group">
                    <Form.Label className="">Title</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      className="form-control"
                      value={
                        selector?.data?.length > 0 && selector.data[0].title
                      }
                      readOnly
                      placeholder="Enter Role Name"
                      type="text"
                      name="title"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label className="">Message</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      className="form-control"
                      readOnly
                      value={
                        selector?.data?.length > 0 && selector.data[0].message
                      }
                      placeholder="Enter Role Name"
                      type="text"
                      name="message"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label className="">Remarks</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      className="form-control"
                      value={reviewRemarks}
                      onChange={(e) => {
                        setReviewRemarks(e.target.value);
                      }}
                      placeholder="Enter Role Name"
                      type="text"
                      name="remarks"
                    />
                  </Form.Group>
                </div>
              </Form>
              <div className="d-flex flex-wrap justify-content-between">
                <Button
                  variant=""
                  onClick={() => {
                    handleSubmit(1);
                  }}
                  disabled={selector?.data[0]?.reviewStatus === (1 || 2)}
                  className="btn btn-primary"
                >
                  Approve
                </Button>
                <Button
                  variant=""
                  onClick={() => {
                    handleSubmit(2);
                  }}
                  disabled={selector?.data[0]?.reviewStatus === (1 || 2)}
                  className="btn btn-danger"
                >
                  Disapprove
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showMessage && <ToastContainer />}
    </>
  );
};

export default GiveReviewToNotification;
