import FetchData from "services/ApiCall/FetchData";
const { createAsyncThunk } = require("@reduxjs/toolkit");

export const getSubjectsMiddleware = createAsyncThunk(
  "get/subjects",
  async (credential, { rejectWithValue }) => {
    console.log(credential);
    try {
      const response = await FetchData(
        "POST",
        credential,
        "get_subjects_from_grades"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
