import { configureStore } from "@reduxjs/toolkit";
import Reducer from "./CombineReducer/Reducer";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["MenusManagementReducer"],
  stateReconciler: hardSet,
};
const persistedReducer = persistReducer(persistConfig, Reducer);
const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }),
});
export const persistor = persistStore(Store);
export default Store;
