import React from 'react'
import { Spinner } from 'react-bootstrap'
const SpinnerComponent = () => {
  return (
    <Spinner
    animation="border"
    className="spinner-border spinner-border-sm"
    role="status"
    style={{color:"#38cab3",margin:"auto"}}
    aria-hidden="true"
  ></Spinner>
  )
}

export default SpinnerComponent