const initialState = {
  status: "idle",
  data: [],
};

const GetSubUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_Sub_USERS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "GET_SUB_USERS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.payload,
      };
    case "GET_SUB_USERS_ERROR":
      return {
        ...state,
        status: "failed",
      };
    default:
      return { ...state };
  }
};

export const getSubUsersSelector = (state) => {
  return state.GetSubUsersReducer;
};
export default GetSubUsersReducer;
