const { createAsyncThunk } = require("@reduxjs/toolkit");

const ChangePasswordMiddleware = createAsyncThunk(
  "change/password",
  async (credential, { rejectWithValue }) => {
    try {
      const promise = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ChangePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Secret-Id": process.env.SecretId,
            "Lang-Code": process.env.LangCode,
            "Secret-Key": process.env.SecretKey,
          },
          body: JSON.stringify(credential),
        }
      );
      const response = await promise.json();
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export default ChangePasswordMiddleware;
