const initialState = {
  status: "idle",
  message: "",
};
const AddUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        status: "success",
        message: action.payload.message,
      };
    case "ADD_USER_ERROR":
      return {
        ...state,
        status: "failed",
        message: action.payload.message,
      };
    default:
      return state;
  }
};
export const AddUserSelector = (state) => state.AddUserReducer;
export default AddUserReducer;
