const { createSlice } = require("@reduxjs/toolkit");
const {
  EditUserRoleMiddleware,
} = require("services/Middleware/EditUserRoleMiddleware");
const initialState = {
  status: "idle",
  message: "",
};
const EditUserRoleSlice = createSlice({
  name: "edit/user/role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EditUserRoleMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditUserRoleMiddleware.fulfilled, (state, action) => {
        console.log(action);
        state.status = "success";
        state.message = action.payload.message;
      })
      .addCase(EditUserRoleMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      }).addDefaultCase((state)=>({...state}))
  },
});
export const editUserRoleSelector = (state) => state.EditUserRoleSlice;
 export default EditUserRoleSlice.reducer;