export const MENUITEMS = [
  {
    Items: [
      {
        title: "Home",
        type: "sub",
        selected: false,
        active: false,
        child: [
          {
            menu_link: `tracking`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Tracking Dashboard",
          },
          {
            menu_link: `publish-notification`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Publish Notification",
          },
        ],
      },
    ],
  },

  {
    Items: [
      {
        title: "User Tables",
        type: "sub",
        selected: false,
        active: false,
        child: [
          {
            menu_link: `user_types`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "User Types",
          },
          {
            menu_link: `roles`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Role",
          },
          {
            menu_link: `menus`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Menus",
          },
          {
            menu_link: `role_menu_mapping`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Role Menu Mapping",
          },
        ],
      },
    ],
  },

  {
    Items: [
      {
        title: "Sub Users",
        type: "sub",
        selected: false,
        active: false,
        child: [
          {
            menu_link: `user-list`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Users",
          },
          {
            menu_link: `add-user`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Add User",
          },
        ],
      },
    ],
  },

  {
    Items: [
      {
        title: "Notification",
        type: "sub",
        selected: false,
        active: false,
        child: [
          {
            menu_link: `notification`,
            type: "link",
            active: false,
            selected: false,
            menu_name: "Notification List",
          },
        ],
      },
    ],
  },
];
