import React, { lazy, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import ProtectedRoute from "./ProtectedRoute";
import { Suspense } from "react";
import SpinnerComponent from "Utils/SpinnerComponent";
import NotificationDetails from "components/NotificationList/NotificationDetails";
import GiveReviewToNotification from "components/NotificationList/GiveReviewToNotification";
import { useSelector } from "react-redux";
import { getRoleMenuMappingSelector } from "services/Slices/RoleMenuMappingSlice";
import NotFound from "components/NotFound/NotFound";
import ShowOnlyNotifcation from "components/NotificationList/ShowOnlyNotifcation";
const Addschedule = lazy(() => import("pages/Addschedule"));
const AddUser = lazy(() => import("pages/AddUser"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Login = lazy(() => import("pages/Login"));
const EditUser = lazy(() => import("pages/EditUser"));
const ShowUsers = lazy(() => import("pages/ShowUsers"));
const Notification = lazy(() => import("pages/Notification"));
const Trackingdashboard = lazy(() => import("pages/Trackingdashboard"));
const Menudashboard = lazy(() => import("components/Menu/Menudashboard"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const UserTypeManagement = lazy(() => import("pages/UserTypeManagement"));
const AddUserType = lazy(() => import("components/UserType/AddUserType"));
const EditUserType = lazy(() => import("components/UserType/EditUserType"));
const UserRoleManagement = lazy(() => import("pages/UserRoleManagement"));
const AddUserRole = lazy(() => import("components/UserRole/AddUserRole"));
const EditUserRole = lazy(() => import("components/UserRole/EditUserRole"));
const RoleMenu = lazy(() => import("pages/RoleMenu"));
const RoleMenuMapping = lazy(() =>
  import("components/RoleMenuMapping/RoleMenuMapping")
);
const MenusManagement = lazy(() => import("pages/MenusManagement"));
const AddAndEditMenu = lazy(() =>
  import("components/ManageMenus/AddAndEditMenu")
);

const Routing = () => {
  const selector = useSelector(getRoleMenuMappingSelector);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (selector?.data?.length > 0 && localStorage.getItem("r_id") !== "34") {
      const isPathAvailable = selector.data.find((item) => {
        return `/${item.menu_link}` === location.pathname;
      });
      if (!isPathAvailable && location.pathname !== "/notifications-list") {
        navigate("/");
      }
    }
  }, [selector]);
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<SpinnerComponent />}>
              <Login />
            </Suspense>
          }
        ></Route>
        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<SpinnerComponent />}>
              <ForgotPassword />
            </Suspense>
          }
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/change-password"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <ChangePassword />
              </Suspense>
            }
          ></Route>
          <Route
            path="/add-user"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <AddUser />
              </Suspense>
            }
          ></Route>
          <Route
            path="/user-list"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <ShowUsers showErrorMessage={true} />
              </Suspense>
            }
          ></Route>
          <Route
            path="/notification"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <Notification />
              </Suspense>
            }
          ></Route>
          <Route
            path="/edit-user"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <EditUser />
              </Suspense>
            }
          ></Route>
          <Route
            path="/tracking"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <Trackingdashboard />
              </Suspense>
            }
          ></Route>

          <Route
            path="/"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <Dashboard />
              </Suspense>
            }
          ></Route>

          <Route
            path="/user_types"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <UserTypeManagement />
              </Suspense>
            }
          ></Route>
          <Route
            path="/publish-notification"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <Menudashboard />
              </Suspense>
            }
          ></Route>
          <Route
            path="/add-schedule"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <Addschedule />
              </Suspense>
            }
          ></Route>
          <Route
            path="/add_user_types"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <AddUserType />
              </Suspense>
            }
          ></Route>
          <Route
            path="/edit_user_types"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <EditUserType />
              </Suspense>
            }
          ></Route>
          <Route
            path="/roles"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <UserRoleManagement />
              </Suspense>
            }
          ></Route>
          <Route
            path="/add_user_role"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <AddUserRole />
              </Suspense>
            }
          ></Route>
          <Route
            path="/edit_user_role"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <EditUserRole />
              </Suspense>
            }
          ></Route>
          <Route
            path="/role_menu_mapping"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <RoleMenu />
              </Suspense>
            }
          ></Route>
          <Route
            path="/add_role_menu_mapping"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <RoleMenuMapping />
              </Suspense>
            }
          ></Route>
          <Route
            path="/menus"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <MenusManagement />
              </Suspense>
            }
          ></Route>
          <Route
            path="/menu-edit-add"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <AddAndEditMenu />
              </Suspense>
            }
          ></Route>
          <Route
            path="/tracking_dashboard"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <NotificationDetails />
              </Suspense>
            }
          ></Route>

          <Route
            path="/review/notification/:id"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <GiveReviewToNotification />
              </Suspense>
            }
          ></Route>
          <Route
            path="/notifications-list"
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <ShowOnlyNotifcation />
              </Suspense>
            }
          ></Route>
        </Route>
      </Routes>
    </>
  );
};
export default Routing;
