
import CommunicationCasesMiddleware from "services/Middleware/CommunicationCasesMiddleware";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  data: [],
  status: "idle",
};
// A function that accepts an initialState , a object of reducer functions , a slice name and automatically generates action creators and action types that correspond to the reducers and state.
const CommunicationCasesSlices = createSlice({
  // A string name for this slice of state. Generated action type constants will use this as a prefix.
  name: "communication/cases",
  initialState,
  //   An object containing Redux "case reducer" functions (functions intended to handle a specific action type, equivalent to a single case statement in a switch).
  reducers: {},
  //   extraReducers allows createSlice to respond to other action types besides the types it has generated.
  extraReducers: (builder) => {
    builder
      .addCase(CommunicationCasesMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CommunicationCasesMiddleware.fulfilled, (state, action) => {
        const { payload } = action;
        const data = payload?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        console.log(action);
        return { ...state, data: data, status: "success" };
      })
      .addCase(CommunicationCasesMiddleware.rejected, (state) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const communicationCasedSelector = (state) =>
  state.CommunicationCasesSlices;
export default CommunicationCasesSlices.reducer;
