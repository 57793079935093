const { createSlice } = require("@reduxjs/toolkit");
const {
  GetNotificationDetailsMiddleware,
} = require("services/Middleware/GetNotificationDetailsMiddleware");
const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const GetNotificationDetailsSlice = createSlice({
  name: "get/notification/details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetNotificationDetailsMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetNotificationDetailsMiddleware.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.status = "success";
        state.data = data;
      })
      .addCase(GetNotificationDetailsMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const GetNotificationDetailSelector = (state) =>
  state.GetNotificationDetailsSlice;
export default GetNotificationDetailsSlice.reducer;
