import FetchData from "services/ApiCall/FetchData";
const { createAsyncThunk } = require("@reduxjs/toolkit");
export const GetStateMiddleware = createAsyncThunk(
  "get/states",
  async (_, { rejectWithValue }) => {
    try {
      const response = await FetchData("GET", _, "states");
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
