import FetchData from "services/ApiCall/FetchData";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const EditUserRoleMiddleware = createAsyncThunk(
  "edit/user/role",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData("POST", credential, "update_user_role");
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
