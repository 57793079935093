import {
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
} from "services/Constants/Constant";

const initialState = {
  status: "idle",
  user_id: null,
};
const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        status: "loading",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: "success",
        user_id: action?.payload?.user_id,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        status: "failed",
      };
    default:
      return {
        ...state,
      };
  }
};
export const loginSelector = (state) => state.LoginReducer;
export default LoginReducer;
