const { createSlice } = require("@reduxjs/toolkit");
const {
  AddRoleMenuMappingMiddleware,
} = require("services/Middleware/AddRoleMenuMappingMiddleware");
const initialState = {
  staus: "idle",
  message: "",
};
const AddRoleMenuMappingSlice = createSlice({
  name: "/add/role/menu/mapping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddRoleMenuMappingMiddleware.pending, (state) => {
        state.staus = "loading";
      })
      .addCase(AddRoleMenuMappingMiddleware.fulfilled, (state, action) => {
        state.staus = "success";
        state.message = action?.payload?.message;
      })
      .addCase(AddRoleMenuMappingMiddleware.rejected, (state, action) => {
        state.staus = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({
        ...state,
      }));
  },
});
export default AddRoleMenuMappingSlice.reducer;
export const addRoleMenuMappingSelector = (state) => state.AddRoleMenuMappingSlice;
