import { UpdateCommunicationMessageMiddleware } from "services/Middleware/UpdateCommunicationMessageMiddleware";
const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  status: "",
  data: [],
  message: "",
};
const UpdateCommunicationMessageSlice = createSlice({
  name: "update/message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateCommunicationMessageMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        UpdateCommunicationMessageMiddleware.fulfilled,
        (state, action) => {
          state.status = "success";
          state.data = action.payload;
        }
      )
      .addCase(
        UpdateCommunicationMessageMiddleware.rejected,
        (state, action) => {
          state.status = "failed";
          state.message = action.payload;
        }
      );
  },
});
export const updateCommunicationMessageSelector = (state) =>
  state.UpdateCommunicationMessageSlice;
export default UpdateCommunicationMessageSlice.reducer;
