import { GetUserRoleMiddleware } from "services/Middleware/GetUserRoleMiddleware";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const GetUserRoleSlice = createSlice({
  name: "get/user/role",
  initialState,
  reducers: {
    updateUserRoleStatus: (state, action) => {
      return {
        ...state,
        data: state.data.map((item) =>
          item.role_id === action.payload.role_id
            ? action.payload.status === 1
              ? { ...item, status: "Active" }
              : { ...item, status: "Inactive" }
            : item
        ),
        message: "success",
      };
    },
    userRoleDelete: (state, action) => {
      return {
        ...state,
        data: state.data.filter((item) => item.role_id !== action.payload),
        message: "success",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserRoleMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetUserRoleMiddleware.fulfilled, (state, action) => {
        const data = action?.payload?.data
          .filter((item) => item.status !== "2")
          .map((item) => ({
            ...item,
            status: item.status === "1" ? "Active" : "Inactive",
          }));
        return { ...state, status: "success", data };
      })
      .addCase(GetUserRoleMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const GetUserRoleSelector = (state) => state.GetUserRoleSlice;
export const { updateUserRoleStatus, userRoleDelete } =
  GetUserRoleSlice.actions;
export default GetUserRoleSlice.reducer;
