import FetchData from "services/ApiCall/FetchData";
const { createAsyncThunk } = require("@reduxjs/toolkit");

export const GetCommunicationListMiddleware = createAsyncThunk(
  "get/totalCount",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData(
        "POST",
        credential,
        "get_communication_list_new"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
