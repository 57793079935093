const { createAsyncThunk } = require("@reduxjs/toolkit");
// A function that accepts a Redux action type string and a callback function that should return a promise. It generates promise lifecycle action types based on the action type prefix that you pass in, and returns a thunk action creator that will run the promise callback and dispatch the lifecycle actions based on the returned promise.

// createAsyncThunk returns a standard Redux thunk action creator. The thunk action creator function will have plain action creators for the pending, fulfilled, and rejected cases attached as nested fields.
const CommunicationCasesMiddleware = createAsyncThunk(
  "get/communication/cases",
  async (_, { rejectWithValue }) => {
    try {
      const promise = await fetch(
        `${process.env.REACT_APP_BASE_URL}/getCommunicationCases`
      );
      const response = await promise.json();
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export default CommunicationCasesMiddleware;
