const { createSlice, current } = require("@reduxjs/toolkit");
const {
  GetUserTypesMiddleware,
} = require("services/Middleware/GetUserTypesMiddleware");

const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const GetUserTypeSlice = createSlice({
  name: "get/user/type",
  initialState,
  reducers: {
    userTypeDelete: (state, action) => {
      console.log(current(state));
      console.log(action);
      return {
        ...state,
        data: state.data.filter((item) => item.user_type_id !== action.payload),
        message: "success",
      };
    },
    userTypeStatus: (state, action) => {
      return {
        ...state,
        data: state.data.map((item) =>
          item.user_type_id === action.payload.user_type_id
            ? action.payload.status === 1
              ? { ...item, status: "Active" }
              : { ...item, status: "Inactive" }
            : item
        ),
        message: "success",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserTypesMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetUserTypesMiddleware.fulfilled, (state, action) => {
        const { data } = action?.payload;
        console.log(data);
        const customizedData = data
          ?.filter((item) => item.status !== "2")
          .map((item) => ({
            ...item,
            status: item.status === "1" ? "Active" : "Inactive",
          }));
        state.status = "success";
        state.data = customizedData;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default GetUserTypeSlice.reducer;
export const { userTypeDelete, userTypeStatus } = GetUserTypeSlice.actions;
export const GetUserTypeSelector = (state) => state.GetUserTypeSlice;
