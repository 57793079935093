import { AddUserTypeMiddlware } from "services/Middleware/AddUserTypeMiddleware";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  status: "idle",
  message: "",
};
const AddUserTypeSlice = createSlice({
  name: "add/user/type",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddUserTypeMiddlware.pending, (state, action) => {
        console.log(action, "in pending");
        state.status = "loading";
      })
      .addCase(AddUserTypeMiddlware.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action?.payload?.message;
      })
      .addCase(AddUserTypeMiddlware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default AddUserTypeSlice.reducer;
export const AddUserTypeSelector = (state) => state.AddUserTypeSlice;