const { createSlice } = require("@reduxjs/toolkit");
const {
  getSubjectsMiddleware,
} = require("services/Middleware/getSubjectsMiddleware");
const initialState = {
  status: "",
  data: [],
  message: "",
};
const SubjectFromGradeSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubjectsMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubjectsMiddleware.fulfilled, (state, action) => {
        const { all_subects } = action?.payload;
        const customizedData = all_subects?.map((item) => ({
          label: item.subjectname,
          value: item.subject_id,
        }));
        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getSubjectsMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      });
  },
});
export const subjectSelector = (state) => state.SubjectFromGradeSlice;
export default SubjectFromGradeSlice.reducer;
