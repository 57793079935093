import { useEffect } from "react";
import Routing from "routes/Routing";
import { useLocation, useNavigate } from "react-router";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("Pipeline checking 2");
  useEffect(() => {
    if (localStorage.getItem("userID")) {
      location.pathname === "/login"
        ? navigate("/zs")
        : navigate(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
