const { createAsyncThunk } = require("@reduxjs/toolkit");
const { default: FetchData } = require("services/ApiCall/FetchData");

export const SendPushNotificationMiddleware = createAsyncThunk(
  "push/notification",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData(
        "POST",
        credential,
        "get_communication_list_data"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
