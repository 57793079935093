import { createAsyncThunk } from "@reduxjs/toolkit";
import FetchData from "services/ApiCall/FetchData";

export const GetRoleMenuMappingMiddleware = createAsyncThunk(
  "get/roleMenuMapping",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData(
        "POST",
        credential,
        "get_role_menu_mapping_list"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
