export const DateFormat = (date) => {
    const dateObj = new Date(date); // give Indian Standard Time
    const getHours = dateObj.getHours();
    const getMinutes = date.split(" ")[1].split(":")[1];
    const AmOrPm = getHours > 12 ? "AM" : "PM";
    return (
      (getHours % 12 === 0 ? 12 : getHours % 12) +
      ":" +
      getMinutes +
      " " +
      AmOrPm
    );
  };