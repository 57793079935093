const { createSlice } = require("@reduxjs/toolkit");
const {
  EditUserTypeMiddleware,
} = require("services/Middleware/EditUserTypeMiddleware");

const initialState = {
  status: "idle",
  message: "",
};
const EditUserTypeSlice = createSlice({
  name: "edit/user/type",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EditUserTypeMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditUserTypeMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action?.payload?.message;
      })
      .addCase(EditUserTypeMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action?.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default EditUserTypeSlice.reducer;
export const EditUserTypeSelector = (state) => state.EditUserTypeSlice;