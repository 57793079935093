const { createSlice } = require("@reduxjs/toolkit");
const {
  SendPushNotificationMiddleware,
} = require("services/Middleware/SendPushNotificationMiddleware");
const initialState = {
  status: "idle",
  message: "",
};
const SendPushNotificationSlices = createSlice({
  name: "send/push/notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SendPushNotificationMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SendPushNotificationMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action?.payload?.message;
      })
      .addCase(SendPushNotificationMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const SendPushNotificationSelector = (state) =>
  state.SendPushNotificationSlices;
export default SendPushNotificationSlices.reducer;
