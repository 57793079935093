const { createSlice } = require("@reduxjs/toolkit");
const {
  EditMenuMiddleware,
} = require("services/Middleware/EditMenuMiddleware");
const initialState = {
  status: "idle",
  message: "",
};
const EditMenuSlice = createSlice({
  name: "edit/menuItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EditMenuMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditMenuMiddleware.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(EditMenuMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      });
  },
});
export default EditMenuSlice.reducer;
export const editMenuSelector = state => state.EditMenuSlice;