const { createAsyncThunk } = require("@reduxjs/toolkit");
const { default: FetchData } = require("services/ApiCall/FetchData");

export const UpdateCommunicationMessageMiddleware = createAsyncThunk(
  "update/communication/message",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData(
        "POST",
        credential,
        "update_communication_message"
      );
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
