import { combineReducers } from "redux";
import ChangePasswordSlice from "services/Slices/ChangePasswordSlice";
import AddUserReducer from "services/Reducer/AddUserReducer";
import EditUserReducer from "services/Reducer/EditUserReducer";
import GetSubUsersReducer from "services/Reducer/GetSubUsersReducer";
import ForgotPasswordReducer from "services/Reducer/ForgotPasswordReducer";
import LoginReducer from "services/Reducer/LoginReducer";
import AddUserTypeSlice from "services/Slices/AddUserTypeSlice ";
import GetUserTypeSlice from "services/Slices/GetUserTypeSlice ";
import EditUserTypeSlice from "services/Slices/EditUserTypeSlice ";
import GetUserRoleSlice from "services/Slices/GetUserRoleSlice";
import AddUserRoleSlice from "services/Slices/AddUserRoleSlice";
import EditUserRoleSlice from "services/Slices/EditUserRoleSlice";
import RoleMenuMappingSlice from "services/Slices/RoleMenuMappingSlice";
import AddRoleMenuMappingSlice from "services/Slices/AddRoleMenuMappingSlice";
import EditRoleMenuMappingSlice from "services/Slices/EditRoleMenuMappingSlice";
import GetMenuSlice from "services/Slices/GetMenuSlice";
import AddMenuSlice from "services/Slices/AddMenuSlice";
import EditMenuSlice from "services/Slices/EditMenuSlice";
import CommunicationCasesSlices from "services/Slices/CommunicationCasesSlices";
import StateSlice from "services/Slices/StateSlice";
import DistrictsSlice from "services/Slices/DistrictsSlice";
import SubjectFromGradeSlice from "services/Slices/SubjectFromGradeSlice";
import GetCommunicationListSlices from "services/Slices/GetCommunicationListSlices";
import SendPushNotificationSlices from "services/Slices/SendPushNotificationSlices";
import GetNotificationSlice from "services/Slices/GetNotificationSlice";
import NotificationDetailsSlice from "services/Slices/NotificationDetailsSlice";
import UpdateNotificationReviewSlice from "services/Slices/UpdateNotificationReviewSlice";
import GetNotificationDetailsSlice from "services/Slices/GetNotificationDetailsSlice";
import UpdateCommunicationMessageSlice from "services/Slices/UpdateCommunicationMessageSlice";
const Reducer = combineReducers({
  LoginReducer,
  ChangePasswordSlice,
  AddUserReducer,
  EditUserReducer,
  GetSubUsersReducer,
  ForgotPasswordReducer,
  AddUserTypeSlice,
  GetUserTypeSlice,
  EditUserTypeSlice,
  GetUserRoleSlice,
  AddUserRoleSlice,
  EditUserRoleSlice,
  RoleMenuMappingSlice,
  EditRoleMenuMappingSlice,
  AddRoleMenuMappingSlice,
  GetMenuSlice,
  AddMenuSlice,
  EditMenuSlice,
  CommunicationCasesSlices,
  StateSlice,
  DistrictsSlice,
  SubjectFromGradeSlice,
  GetCommunicationListSlices,
  SendPushNotificationSlices,
  GetNotificationSlice,
  NotificationDetailsSlice,
  UpdateNotificationReviewSlice,
  GetNotificationDetailsSlice,
  UpdateCommunicationMessageSlice,
});
export default Reducer;
