import {
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
} from "services/Constants/Constant";

const initialState = {
  status: "idle",
  message: "",
};

const ForgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        status: "loading",
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        status: "failed",
        message: "Internal server error",
      };
    default:
      return {
        ...state,
      };
  }
};

export const forgotPasswordSelector = (state) => state.ForgotPasswordReducer;
export default ForgotPasswordReducer;
