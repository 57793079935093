import useNotification from "Utils/useNotification";
import React, { useEffect } from "react";
import { useState } from "react";
import { Badge, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetNotificationSelector } from "services/Slices/GetNotificationSlice";
import { getDate } from "Utils/ConvertToReadableTime";
import { DateFormat } from "Utils/DateFormat";

const ShowOnlyNotifcation = (props) => {
  const [notificationDuration, setNotificationDuration] =
    useState("All Notification");
  const { error, message } = useNotification();
  const [notificationList, setNotificationList] = useState([]);
  const [filteredNotification, setFilteredNotification] = useState([]);
  const navigate = useNavigate();
  const selector = useSelector(GetNotificationSelector);
  console.log("selector => ", selector);
  useEffect(() => {
    if (selector?.data.length > 0) {
      const { data } = selector;
      setNotificationList(data);
      setFilteredNotification(data);
    }
  }, [selector]);
  return (
    <div>
      <Card className="box-shadow-0">
        {props?.data?.length > 0 && (
          <div className="breadcrumb-header justify-content-between">
            <ButtonGroup className="ms-2 mt-2 mb-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  aria-expanded="false"
                  aria-haspopup="true"
                  className="btn ripple btn-rounded btn-primary"
                  data-bs-toggle="dropdown"
                  id="dropdownMenuButton4"
                  type="button"
                >
                  {notificationDuration}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ margin: "0px" }}
                  className="dropdown-menu tx-13"
                >
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setNotificationDuration("This week");
                      setFilteredNotification(() => {
                        return notificationList.filter((item) => {
                          const notificationDate = new Date(item.createdAt);
                          const today = new Date();
                          const startOfWeek = new Date();
                          startOfWeek.setDate(today.getDate() - today.getDay());
                          return (
                            notificationDate >= startOfWeek &&
                            notificationDate < today
                          );
                        });
                      });
                    }}
                  >
                    this Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setNotificationDuration("Today");
                      setFilteredNotification(() => {
                        return notificationList.filter((item) => {
                          const today = new Date();
                          const notificationDate = new Date(item.createdAt);
                          return (
                            today.toDateString() ===
                            notificationDate.toDateString()
                          );
                        });
                      });
                    }}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setNotificationDuration("All Notification");
                      setFilteredNotification(notificationList);
                    }}
                  >
                    All Notification
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </div>
        )}

        <div className="container">
          <Card.Body className="pt-0 position-relative">
            <ul className="notification">
              {filteredNotification?.map((item, index) => (
                <li
                  key={index}
                  //   style={{ cursor: "pointer" }}
                  //   onClick={() => {
                  //     if (props?.data) {
                  //       navigate("/tracking_dashboard");
                  //       localStorage.setItem("s_id", item.id);
                  //     } else {
                  //       navigate(`/review/notification/${item.id}`);
                  //     }
                  //   }}
                >
                  <div className="notification-time">
                    <span className="date">{getDate(item.createdAt)}</span>
                    <span className="time">{DateFormat(item?.createdAt)}</span>
                  </div>
                  <div className="notification-icon">
                    <Link to="#"></Link>
                  </div>
                  <div className="notification-body">
                    <div className="media mt-0 ">
                      <div className="mb-3  me-3 my-auto shadow">
                        <Badge
                          bg=""
                          className="float-end badge notification-badge"
                        >
                          <span className="tx-11 font-weight-semibold">
                            {item.reviewStatus === 0
                              ? "Pending"
                              : item.reviewStatus === 1
                              ? "Approved"
                              : "Disapprove"}
                          </span>
                        </Badge>
                      </div>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <div className="mt-0">
                            <h5 className="mb-1 tx-15 font-weight-semibold text-dark">
                              {item?.title}
                            </h5>
                            <p className="mb-0 tx-13 mb-0 text-muted">
                              {item?.message}
                            </p>
                          </div>
                          <div className="ms-auto">
                            <Badge
                              bg=""
                              className="float-end badge notification-badge"
                            >
                              <span className="tx-11 font-weight-semibold">
                                {DateFormat(item?.createdAt)}
                              </span>
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
};

export default ShowOnlyNotifcation;
