import React, { Fragment, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getRoleMenuMappingSelector } from "services/Slices/RoleMenuMappingSlice";
import useRoleMenuMapping from "Utils/useRoleMenuMapping";
import { ToastContainer, toast } from "react-toastify";
import { MENUITEMS } from "./SideMenu";
const Sidebar = () => {
  const { isError, errorMessage } = useRoleMenuMapping();
  const selector = useSelector(getRoleMenuMappingSelector);
  const [sideMenu, setSideMenu] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (
      document.body.classList.contains("horizontal") &&
      window.innerWidth >= 992
    ) {
      clearMenuActive();
    }
    let mainContent = document.querySelector(".main-content");

    //when we click on the body to remove
    mainContent.addEventListener("click", mainContentClickFn);
    return () => {
      mainContent.removeEventListener("click", mainContentClickFn);
    };
  }, [location]);

  //  In Horizontal When we click the body it should we Closed using in useEfffect Refer line No:16
  function mainContentClickFn() {
    if (
      document.body.classList.contains("horizontal") &&
      window.innerWidth >= 992
    ) {
      clearMenuActive();
    }
  }
  //<-------End---->
  function clearMenuActive() {
    sideMenu.map((mainlevel) => {
      if (mainlevel.Items) {
        mainlevel.Items.map((sublevel) => {
          sublevel.active = false;
          if (sublevel.child) {
            sublevel.child.map((sublevel1) => {
              sublevel1.active = false;
              if (sublevel1.child) {
                sublevel1.child.map((sublevel2) => {
                  sublevel2.active = false;
                  if (sublevel2.child) {
                    sublevel2.child.map((sublevel3) => {
                      sublevel3.active = false;
                      return sublevel3;
                    });
                  }
                  return sublevel2;
                });
              }
              return sublevel1;
            });
          }
          return sublevel;
        });
      }
      return mainlevel;
    });
    setSideMenu((arr) => [...arr]);
  }

  function Onhover() {
    if (document.querySelector(".app")) {
      if (document.querySelector(".app")?.classList.contains("sidenav-toggled"))
        document.querySelector(".app")?.classList.add("sidenav-toggled-open");
    }
  }
  function Outhover() {
    if (document.querySelector(".app")) {
      document.querySelector(".app")?.classList.remove("sidenav-toggled-open");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("r_id") === "34") {
      setSideMenu(MENUITEMS);
    } else if (isError === true) {
      toast.error(errorMessage);
    } else if (selector?.data?.length > 0 && sideMenu.length === 0) {
      const menus = selector.data.filter(
        (item) => item.role_id === +localStorage.getItem("r_id")
      );
      const findChildMenu = menus.map((menuItem) => {
        if (menuItem.is_parent) {
          const child = selector.data
            .map((item) => {
              return menuItem.menu_id === item.sub_menu
                ? { ...item, type: "link", active: false, selected: false }
                : "";
            })
            .filter((item) => item !== "");
          const obj = {
            title: menuItem.menu_name,
            Items: [
              {
                title: menuItem.menu_name,
                child,
                type: "sub",
                selected: false,
                active: false,
              },
            ],
          };
          return obj;
        }
      });
      const finalValue = findChildMenu.filter((item) => item !== undefined);

      setSideMenu(finalValue);
    }
  }, [selector, isError]);

  function toggleSidemenu(item) {
    if (
      !document.body.classList.contains("horizontalmenu-hover") ||
      window.innerWidth < 992
    ) {
      // To show/hide the menu
      if (!item.active) {
        sideMenu.map((mainlevel) => {
          if (mainlevel.Items) {
            mainlevel.Items.map((sublevel) => {
              sublevel.active = false;
              if (item === sublevel) {
                sublevel.active = true;
              }
              if (sublevel.child) {
                sublevel.child.map((sublevel1) => {
                  sublevel1.active = false;
                  if (item === sublevel1) {
                    sublevel.active = true;
                    sublevel1.active = true;
                  }
                  if (sublevel1.child) {
                    sublevel1.child.map((sublevel2) => {
                      sublevel2.active = false;
                      if (item === sublevel2) {
                        sublevel.active = true;
                        sublevel1.active = true;
                        sublevel2.active = true;
                      }
                      if (sublevel2.child) {
                        sublevel2.child.map((sublevel3) => {
                          sublevel3.active = false;
                          if (item === sublevel3) {
                            sublevel.active = true;
                            sublevel1.active = true;
                            sublevel2.active = true;
                            sublevel3.active = true;
                          }
                          return sublevel2;
                        });
                      }
                      return sublevel2;
                    });
                  }
                  return sublevel1;
                });
              }
              return sublevel;
            });
          }
          return mainlevel;
        });
      } else {
        item.active = !item.active;
      }
    }
    setSideMenu((arr) => [...arr]);
  }
  return (
    <div className="sticky" style={{ marginBottom: "-50px" }}>
      <aside
        className="app-sidebar position-fixed sidebarWidth"
        onMouseOver={() => Onhover()}
        onMouseOut={() => Outhover()}
      >
        <Scrollbars className="hor-scroll" style={{ position: "absolute" }}>
          <div
            className="main-sidebar-header active position-fixed"
            // style={{ zIndex: "1000" }}
          >
            <NavLink className="header-logo active" to="/">
              <img
                src="https://devui.auroscholar.org/assets/img/logo.svg"
                className="main-logo  desktop-logo"
                alt="logo"
              />
              <img
                src={require("../../assets/img/brand/logo-white.png")}
                className="main-logo  desktop-dark"
                alt="logo"
              />
              <img
                src={require("../../assets/img/brand/favicon.png")}
                className="main-logo  mobile-logo"
                alt="logo"
              />
              <img
                src={require("../../assets/img/brand/favicon-white.png")}
                className="main-logo  mobile-dark"
                alt="logo"
              />
            </NavLink>
          </div>
          <div className="main-sidemenu">
            <div className="slide-left disabled" id="slide-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#7b8191"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
              </svg>
            </div>

            <ul className="side-menu ">
              {sideMenu?.map((Item, itemi) => (
                <Fragment key={itemi + Math.random() * 100}>
                  {Item.Items.map((menuItem, i) => (
                    <li
                      className={`slide ${
                        menuItem.selected ? "is-expanded" : ""
                      } ${menuItem.active ? "is-expanded" : ""}`}
                      key={i}
                    >
                      {menuItem.type === "link" ? (
                        <NavLink
                          // to={menuItem.path + "/"}
                          className={`side-menu__item ${
                            menuItem.selected ? " active" : ""
                          }`}
                        >
                          {menuItem.icon}
                          <span className="side-menu__label">
                            {menuItem.title}
                          </span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {menuItem.type === "sub" ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            event.preventDefault();
                            toggleSidemenu(menuItem);
                          }}
                          className={`side-menu__item ${
                            menuItem.selected ? "active is-expanded" : ""
                          }`}
                        >
                          <span className="side-menu__label">
                            {menuItem.title}
                            {menuItem.active}
                          </span>
                          <i className="angle fe fe-chevron-right"></i>
                        </span>
                      ) : (
                        ""
                      )}

                      {menuItem?.child ? (
                        <ul
                          className={`slide-menu ${
                            menuItem.active ? "open" : ""
                          }`}
                          style={
                            menuItem.active
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem?.child.map((childrenItem, index) => {
                            console.log(childrenItem, "childrenItem");
                            return (
                              <li
                                key={index}
                                className={`sub-slide  ${
                                  childrenItem.active ? "is-expanded" : ""
                                }`}
                              >
                                {childrenItem.type === "link" ? (
                                  <span>
                                    <NavLink
                                      to={"/" + childrenItem.menu_link}
                                      state={1}
                                      className="slide-item"
                                    >
                                      {childrenItem.menu_name}
                                      {childrenItem.active}
                                    </NavLink>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
        </Scrollbars>
      </aside>
      {isError === true && <ToastContainer />}
    </div>
  );
};

export default Sidebar;
