import { GetDistrictsMiddleware } from "services/Middleware/GetDistrictsMiddleware";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const DistrictsSlice = createSlice({
  name: "districts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetDistrictsMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetDistrictsMiddleware.fulfilled, (state, action) => {
        console.log(action);
        const { districts } = action?.payload;
        const customizedData = districts?.map((item) => ({
          label: item.district_name,
          value: item.district_id,
        }));
        state.status = "success";
        state.data = state.data.concat(customizedData);
        state.message = action?.payload?.message;
      })
      .addCase(GetDistrictsMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const districtSelector = (state) => state.DistrictsSlice;
export default DistrictsSlice.reducer;
