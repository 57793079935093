import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetNotificationMiddleware } from "services/Middleware/GetNotificationMiddleware";

const useNotification = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const getNotification = async () => {
    try {
      await dispatch(GetNotificationMiddleware({ reviewStatus: "" })).unwrap();
    } catch (error) {
      setError(true);
      setMessage(error?.message);
    }
  };
  useEffect(() => {
    getNotification();
  }, []);
  return { error, message };
};

export default useNotification;
