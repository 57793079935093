const { createSlice } = require("@reduxjs/toolkit");
const { AddMenuMiddleware } = require("services/Middleware/AddMenuMiddleware");

const initialState = {
  status: "idle",
  message: "",
};
const AddMenuSlice = createSlice({
  name: "add/menuItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddMenuMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AddMenuMiddleware.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(AddMenuMiddleware.rejected, (state) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default AddMenuSlice.reducer;
export const addMenuSelector = (state) => state.AddMenuSlice;
