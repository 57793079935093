import FetchData from "services/ApiCall/FetchData";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const AddUserRoleMiddleware = createAsyncThunk(
  "add/user/role",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await FetchData("POST", credential, "add_user_role");
      const { response_code, message } = response;
      if (response_code !== 200) {
        throw new Error(message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);
