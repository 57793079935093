import { MenusManagementMiddleware } from "services/Middleware/MenusManagementMiddleware";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  status: "idle",
  data: [],
  message: "",
};
const GetMenuSlice = createSlice({
  name: "get/menu/list",
  initialState,
  reducers: {
    updateMenusStatus: (state, action) => {
      const { data } = state;
      const { id, status } = action.payload;
      const filteredData = data?.map((item) =>
        item.id === id
          ? { ...item, status: status === 1 ? "Active" : "Inactive" }
          : { ...item }
      );
      return {
        ...state,
        data: filteredData,
      };
    },
    deleteMenus: (state, action) => {
      const { data } = state;
      const filteredData = data?.filter((item) => item.id !== action.payload);
      return {
        ...state,
        data: filteredData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MenusManagementMiddleware.pending, (state) => {
        state.status = "loading";
      })
      .addCase(MenusManagementMiddleware.fulfilled, (state, action) => {
        const { data } = action.payload;
        const customizedData = data
          ?.filter((item) => item.status !== "2")
          .map((item) => ({
            ...item,
            is_parent: item.is_parent === 1 ? "True" : "False",
            status: item.status === "1" ? "Active" : "Inactive",
          }));
        state.status = "success";
        state.data = customizedData;
      })
      .addCase(MenusManagementMiddleware.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export default GetMenuSlice.reducer;
export const getMenuSelector = (state) => state.GetMenuSlice;
export const { updateMenusStatus, deleteMenus } = GetMenuSlice.actions;
